import { css } from 'styled-components';

export const boxShadow = css`
	box-shadow: 0 4px 16px #00000033;
`;

export const positionAll0 = css`
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

export const reset = css`
	padding: 0;
	margin: 0;
	background-color: transparent;
	border: none;
	appearance: none;
`;

export const transition = css`
	transition-timing-function: ease;
	transition-duration: 150ms;
`;

export const hide = css`
	${transition}
	opacity: 100%;
	transition-property: opacity;

	&.hide,
	&.hideSlowly {
		pointer-events: none;
		opacity: 0%;
	}

	&.hideSlowly {
		transition-duration: 500ms;
	}
`;

export const horizontalRuleContainer = css`
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
`;

export const horizontalRule = css`
	height: 2px;
	flex-grow: 2;
`;

export const textInHorizontalRule = css`
	padding: 0 0.5rem;
	flex-grow: 3;
`;
