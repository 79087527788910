import type { Theme } from '@drop-party/sanity';
import { isUndefined, omitBy } from 'lodash/fp';
import Head from 'next/head';
import React, { Fragment } from 'react';
import type { FunctionComponent } from 'react';
import type { DefaultTheme } from 'styled-components';
import { ThemeProvider, createGlobalStyle, css } from 'styled-components';

const cardBleedPx = 16;
const cardBorder = 'none';
const cardBorderRadiusPx = 0;
const cardMarginHorizontalPx = 16;
const errorColor = '#ff0000';
const fontFamily = '"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
const fontSize = 16;
const fontWeight = 500;
const imageFontSize = 8;
const lineHeight = 1.5;
const overlayContentColor = '#ffffff';
const surfaceBorder = 'none';
const surfaceBorderRadius = 4;

export const fontFamilyUrls = ['https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700;800;900&display=swap'];

interface Color {
	_type: 'color';
	hex: string;
}

const GlobalStyle = createGlobalStyle`
	/* stylelint-disable selector-max-id -- Applying global styles */

	#root,
	#__next {
		background: ${({ theme: { bodyBackground } }) => bodyBackground};
	}
	/* stylelint-enable selector-max-id -- Applying global styles */
`;

const dark: DefaultTheme = {
	cardBleedPx,
	cardBorder,
	cardBorderRadiusPx,
	cardMarginHorizontalPx,
	errorColor,
	fontFamily,
	fontFamilyUrls,
	fontSize,
	fontWeight,
	imageFontSize,
	lineHeight,
	overlayContentColor,
	surfaceBorder,
	surfaceBorderRadius,
	bannerBackground:                 '#000000',
	bodyBackground:                   '#000000',
	buttonBackground:                 '#2e2e2e',
	buttonBorderColor:                '#979797',
	buttonColor:                      '#ffffff',
	buttonDisabledBackground:         '#1d1d1d',
	buttonDisabledBorderColor:        '#979797',
	buttonDisabledColor:              '#979797',
	buttonEmphasisBackground:         '#ffffff',
	buttonEmphasisBorderColor:        '#ffffff',
	buttonEmphasisColor:              '#000000',
	buttonSubmitSucceededBackground:  '#000000',
	buttonSubmitSucceededBorderColor: '#ffffff',
	buttonSubmitSucceededColor:       '#ffffff',
	color:                            '#ffffff',
	dividerColor:                     '#464646',
	emphasisColor:                    '#ffffff',
	inputBackground:                  '#6d6d6d',
	inputBorderColor:                 '#6d6d6d',
	inputColor:                       '#ffffff',
	inputDirtyBorderColor:            '#ffffff',
	inputEmphasisBorderColor:         '#ffffff',
	inputPlaceholderColor:            '#999999',
	invertedColor:                    '#000000',
	name:                             'Dark',
	noticeBackground:                 '#6d2323',
	purchaseTextLinkColor:            '#ffffff',
	removeLineItemColor:              '#ff7373',
	secondaryColor:                   '#c8c8c8',
	surfaceBackground:                '#1d1d1d',
};

export const light: DefaultTheme = {
	cardBleedPx,
	cardBorder,
	cardBorderRadiusPx,
	cardMarginHorizontalPx,
	errorColor,
	fontFamily,
	fontFamilyUrls,
	fontSize,
	fontWeight,
	imageFontSize,
	lineHeight,
	overlayContentColor,
	surfaceBorder,
	surfaceBorderRadius,
	bannerBackground:                 '#f5f5f5',
	bodyBackground:                   '#ffffff',
	buttonBackground:                 '#2e2e2e',
	buttonBorderColor:                '#2e2e2e',
	buttonColor:                      '#ffffff',
	buttonDisabledBackground:         '#f2f2f2',
	buttonDisabledBorderColor:        '#f2f2f2',
	buttonDisabledColor:              '#979797',
	buttonEmphasisBackground:         '#000000',
	buttonEmphasisBorderColor:        '#000000',
	buttonEmphasisColor:              '#ffffff',
	buttonSubmitSucceededBackground:  '#ffffff',
	buttonSubmitSucceededBorderColor: '#000000',
	buttonSubmitSucceededColor:       '#000000',
	color:                            '#2e2e2e',
	dividerColor:                     '#d1d1d1',
	emphasisColor:                    '#000000',
	inputBackground:                  '#ffffff',
	inputBorderColor:                 '#d1d1d1',
	inputColor:                       '#2e2e2e',
	inputDirtyBorderColor:            '#2e2e2e',
	inputEmphasisBorderColor:         '#000000',
	inputPlaceholderColor:            '#cccccc',
	invertedColor:                    '#ffffff',
	name:                             'Light',
	noticeBackground:                 '#fdf5f5',
	purchaseTextLinkColor:            '#2500bb',
	removeLineItemColor:              '#ff0000',
	secondaryColor:                   '#787878',
	surfaceBackground:                '#ffffff',
};

export const themes: DefaultTheme[] = [light, dark];

export const basic = css`
	font-family: ${({ theme: { fontFamily } }) => fontFamily};
	font-size: ${({ theme: { fontSize } }) => fontSize}px;
	font-weight: ${({ theme: { fontWeight } }) => fontWeight};
	line-height: ${({ theme: { lineHeight } }) => lineHeight};
	color: ${({ theme: { color } }) => color};
`;

export const SanityThemeProvider: FunctionComponent<{ globals?: boolean; theme?: Omit<Theme, '_type'> }> = ({ children, globals, theme }) => (
	<ThemeProvider
		theme={(currentTheme) => ({
			...currentTheme,
			...theme && omitBy(isUndefined, {
				...theme,
				buttonBorderColor:                (theme.buttonBorderColor as Color | undefined)?.hex,
				buttonColor:                      (theme.buttonColor as Color | undefined)?.hex,
				buttonDisabledBorderColor:        (theme.buttonDisabledBorderColor as Color | undefined)?.hex,
				buttonDisabledColor:              (theme.buttonDisabledColor as Color | undefined)?.hex,
				buttonEmphasisBorderColor:        (theme.buttonEmphasisBorderColor as Color | undefined)?.hex,
				buttonEmphasisColor:              (theme.buttonEmphasisColor as Color | undefined)?.hex,
				buttonSubmitSucceededBorderColor: (theme.buttonSubmitSucceededBorderColor as Color | undefined)?.hex,
				buttonSubmitSucceededColor:       (theme.buttonSubmitSucceededColor as Color | undefined)?.hex,
				color:                            (theme.color as Color | undefined)?.hex,
				dividerColor:                     (theme.dividerColor as Color | undefined)?.hex,
				emphasisColor:                    (theme.emphasisColor as Color | undefined)?.hex,
				errorColor:                       (theme.errorColor as Color | undefined)?.hex,
				inputBorderColor:                 (theme.inputBorderColor as Color | undefined)?.hex,
				inputColor:                       (theme.inputColor as Color | undefined)?.hex,
				inputDirtyBorderColor:            (theme.inputDirtyBorderColor as Color | undefined)?.hex,
				inputEmphasisBorderColor:         (theme.inputEmphasisBorderColor as Color | undefined)?.hex,
				inputPlaceholderColor:            (theme.inputPlaceholderColor as Color | undefined)?.hex,
				invertedColor:                    (theme.invertedColor as Color | undefined)?.hex,
				overlayContentColor:              (theme.overlayContentColor as Color | undefined)?.hex,
				purchaseTextLinkColor:            (theme.purchaseTextLinkColor as Color | undefined)?.hex,
				removeLineItemColor:              (theme.removeLineItemColor as Color | undefined)?.hex,
				secondaryColor:                   (theme.secondaryColor as Color | undefined)?.hex,
			}),
		})}
	>
		{globals && (
			<>
				{theme?.bodyBackground && (
					<>
						<GlobalStyle />

						<Head>
							<meta name="theme-color" content={theme.bodyBackground} />
						</Head>
					</>
				)}

				<Head>
					{theme?.fontFamilyUrls?.map((fontFamilyUrl) => (
						<Fragment key={fontFamilyUrl}>
							<link rel="preconnect" href={new URL(fontFamilyUrl).origin} />
							<link rel="stylesheet" href={fontFamilyUrl} />
						</Fragment>
					))}
				</Head>
			</>
		)}
		{children}
	</ThemeProvider>
);
