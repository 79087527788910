import * as Sentry from '@sentry/react';
import { compose } from 'lodash/fp';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import type { ComponentType, FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import 'swiper/swiper.scss'; // TODO Get swiper scss into styled-components somehow

import { GlobalStyles } from 'components/globals';
import { light } from 'components/themes';

const MyApp: FunctionComponent<AppProps> = ({ Component, pageProps }) => (
	<>
		<DefaultSeo
			defaultTitle="Drop Party"
			titleTemplate="%s | Drop Party"
			openGraph={{
				images: [
					{
						alt:    'Drop Party',
						height: 1200,
						url:    '/drop-party-logo.png',
						width:  630,
					},
				],
			}}
		/>

		<Head>
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
		</Head>

		<ThemeProvider theme={light}>
			<GlobalStyles />

			<Component {...pageProps} />
		</ThemeProvider>
	</>
);

export default compose(
	Sentry.withProfiler,
	<T extends Record<string, any>>(Component: ComponentType<T>): ComponentType<T> => Sentry.withErrorBoundary(Component, { showDialog: true })
)(MyApp);
