import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { transition } from 'components/styles';
import { basic } from 'components/themes';

export const GlobalStyles = createGlobalStyle`
	/* stylelint-disable selector-max-type -- Global Resets */
	/* stylelint-disable selector-max-universal -- Global Resets */
	${normalize}

	html,
	body {
		${basic}
		width: 100%;
		padding: 0;
		margin: 0;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* stylelint-disable-next-line property-no-unknown -- works on modern browsers */
		font-smoothing: antialiased;
		text-rendering: geometricPrecision;
		overscroll-behavior: none;
	}

	body {
		min-height: 100vh;
		/* stylelint-disable-next-line declaration-block-no-duplicate-properties -- safari */
		min-height: stretch;
	}

	html {
		height: stretch;
	}

	* {
		box-sizing: border-box;
		/* stylelint-disable-next-line declaration-no-important -- safari */
		-webkit-tap-highlight-color: transparent !important;

		&:not(:focus-visible) {
			outline: none;
		}
	}

	a {
		text-decoration: none;
	}

	button,
	input,
	optgroup,
	select,
	textarea {
		line-height: inherit;
		color: inherit;
	}

	select {
		&:disabled {
			opacity: inherit;
		}
	}

	fieldset {
		padding: 0;
		margin: 0;
		border: none;
		margin-inline: 0;
		min-inline-size: 0;
		padding-block: 0;
		padding-inline: 0;
	}

	/* stylelint-disable selector-max-id -- Applying global styles */

	#root,
	#__next {
		${transition}
		transition-property: background-color;
	}
	/* stylelint-enable selector-max-id -- Applying global styles */
`;
